.experience__icon {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  place-items: center;
  gap: 1rem;
  font-size: 6rem;
}
.icon {
  color: var(--color-white);
  transition: var(--transition);
}
.icon:hover {
  color: var(--color-primary-variant);
}
.littlepadding {
  padding: 30px 0px;
}
@media screen and (max-width: 1024px) {
  .experience__icon {
    font-size: 5rem;
  }
}

@media screen and (max-width: 600px) {
  .experience__icon {
    font-size: 3rem;
  }
}
