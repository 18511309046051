@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,600;1,500&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 258, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 98%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: black;
  color: var(--color-white);
  line-height: 1.7;
  /* background-image: url(../src/assets/bg-texture.png); */
}
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}
section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}
.text-light {
  color: var(--color-light);
}
a {
  color: var(--color-primary);
  transition: var(--transition);
}
a:hover {
  color: var(--color-white);
}


img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* media query for tablet */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  .section {
    margin-top: 6rem;
  }
}

/* media query for phone */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sd);
  }
  .section > h2 {
    margin-top: 2rem;
  }
}
 