.contact-container {
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}
.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}
.contact__option:hover {
  background: transparent;
  border-color: var(--color-bg-variant);
}
.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}
input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-bg-variant);
  resize: none;
}
.inptext {
  color: var(--color-white);
}

@media screen and (max-width: 1024px) {
    .contact-container{ 
        grid-template-columns: 40% 50%;
        gap: 10%;
    }
}

@media screen and (max-width: 600px) {
  .contact-container {
    grid-template-columns: 1fr;
     
  }
  .contact__options{
    margin: 0 1rem;
  }
  form{
    margin: 0 1rem;
  }
}
